<template>
  <v-data-table
      :headers="headers"
      :items="eligibilities"
      sort-by="calories"
      class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>Eligibility</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialog"
            max-width="800px"
            persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon color="blue">mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <div class="col-md-6">
                    <v-select
                        :items="[2013,2014,2015,2016,2017,2017,2018,2019,2020,2021]"
                        v-model="editedItem.ssc_passing_year"
                        label="SSC passing year"
                    ></v-select>
                  </div>
                  <div class="col-md-6">
                    <v-select
                        :items="[2013,2014,2015,2016,2017,2018,2019,2020,2021]"
                        v-model="editedItem.hsc_passing_year"
                        label="HSC passing year"
                    ></v-select>
                  </div>
                  <div class="col-md-6">
                    <v-text-field
                        v-model="editedItem.ssc_gpa_without_optional"
                        label="Minimum SSC GPA (without optional)"
                    ></v-text-field>
                  </div>
                  <div class="col-md-6">
                    <v-text-field
                        v-model="editedItem.ssc_gpa_with_optional"
                        label="Minimum SSC GPA (with optional)"
                    ></v-text-field>
                  </div>
                  <div class="col-md-6">
                    <v-text-field
                        v-model="editedItem.hsc_gpa_without_optional"
                        label="Minimum HSC GPA (without optional)"
                    ></v-text-field>
                  </div>
                  <div class="col-md-6">
                    <v-text-field
                        v-model="editedItem.hsc_gpa_with_optional"
                        label="Minimum HSC GPA (with optional)"
                    ></v-text-field>
                  </div>
                  <div class="col-12">
                    <v-text-field v-model="editedItem.total_gpa" label="Total GPA">
                    </v-text-field>
                  </div>
                  <div class="col-md-6">
                    <v-select
                        multiple
                        chips
                        :items="['Science','Commerce','Arts']"
                        v-model="editedItem.ssc_group"
                        label="SSC group"
                    ></v-select>
                  </div>
                  <div class="col-md-6">
                    <v-select
                        multiple
                        chips
                        :items="['Science','Commerce','Arts']"
                        v-model="editedItem.hsc_group"
                        label="HSC group"
                    ></v-select>
                  </div>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.photo="{ item }">
      <v-img max-width="100" :src="item.photo"></v-img>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          color="blue"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
          color="red"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
          color="primary"
          @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import axios from "axios";

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    photo: null,
    form_fill_up_start: false,
    form_fill_up_end: false,
    exam_start: false,
    interview_date: false,
    admit_card_start: false,
    admit_card_end: false,
    headers: [
      {
        text: 'SSC passing year',
        value: 'ssc_passing_year'
      },
      {
        text: 'SSC GPA without optional',
        value: 'ssc_gpa_without_optional'
      },
      {
        text: 'SSC GPA with optional',
        value: 'ssc_gpa_with_optional'
      },
      {
        text: 'ssc_group',
        value: 'ssc_group'
      },
      {
        text: 'HSC passing year',
        value: 'hsc_passing_year'
      },
      {
        text: 'HSC GPA without optional',
        value: 'hsc_gpa_without_optional'
      },
      {
        text: 'HSC GPA with optional',
        value: 'hsc_gpa_with_optional'
      },
      {
        text: 'Total GPA',
        value: 'total_gpa'
      },
      {
        text: 'HSC Group',
        value: 'hsc_group'
      },
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    eligibilities: [],
    editedIndex: -1,
    editedItem: {
      ssc_passing_year: '',
      ssc_gpa_without_optional: '',
      ssc_gpa_with_optional: '',
      ssc_group: [],
      hsc_passing_year: '',
      hsc_gpa_without_optional: '',
      hsc_gpa_with_optional: '',
      total_gpa: '',
      hsc_group: [],
    },
    defaultItem: {
      ssc_passing_year: '',
      ssc_gpa_without_optional: '',
      ssc_gpa_with_optional: '',
      ssc_group: [],
      hsc_passing_year: '',
      hsc_gpa_without_optional: '',
      hsc_gpa_with_optional: '',
      total_gpa: '',
      hsc_group: [],
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    form() {
      let formData = new FormData()
      if (this.editedIndex > -1) {
        formData.append('_method', 'PUT')
      } else {
        formData.append('unit_id', this.$route.params.unitId)
      }
      formData.append('ssc_passing_year', this.editedItem.ssc_passing_year)
      formData.append('ssc_gpa_without_optional', this.editedItem.ssc_gpa_without_optional)
      formData.append('ssc_gpa_with_optional', this.editedItem.ssc_gpa_with_optional)
      formData.append('ssc_group', this.editedItem.ssc_group)
      formData.append('hsc_passing_year', this.editedItem.hsc_passing_year)
      formData.append('hsc_gpa_without_optional', this.editedItem.hsc_gpa_without_optional)
      formData.append('hsc_gpa_with_optional', this.editedItem.hsc_gpa_with_optional)
      formData.append('hsc_group', this.editedItem.hsc_group)
      formData.append('total_gpa', this.editedItem.total_gpa)
      return formData
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      const url = 'admin/eligibility?id=' + this.$route.params.unitId
      axios.get(url).then((response) => {
        this.eligibilities = response.data.eligibilities
      }).catch(() => {

      })
    },

    editItem(item) {
      this.editedIndex = this.eligibilities.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.ssc_group = item.ssc_group ? item.ssc_group === 'null' ? [] : item.ssc_group.split(',') : []
      this.editedItem.hsc_group = item.hsc_group ? item.hsc_group === 'null' ? [] : item.hsc_group.split(',') : []
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.eligibilities.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      const url = 'admin/eligibility/' + this.editedItem.id
      let editedIndex = this.editedIndex
      axios.delete(url).then(() => {
        this.eligibilities.splice(editedIndex, 1)
        this.closeDelete()
      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        const url = 'admin/eligibility/' + this.editedItem.id
        let editedIndex = this.editedIndex
        axios.post(url, this.form).then((response) => {
          Object.assign(this.eligibilities[editedIndex], response.data)
        })
      } else {
        const url = 'admin/eligibility'
        axios.post(url, this.form).then((response) => {
          this.eligibilities.push(response.data)
        })
      }
      this.close()
    },
  },
}
</script>
